import _ from 'underscore';

// 订单查询
const detail = require(`@/lib/data-service/flight/flight_domestic_buyer_manage_order_detail`);
// 支付
const pay = require(`@/lib/data-service/flight/flight_domestic_buyer_pay`);

export default {
  data() {
    var cardNo = (rule, value, callback) => {
      console.log(rule, this);
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (reg.test(value) === false) {
        callback(new Error("请输入正确身份证号码"));
      }
    };
    var mobile = (rule, value, callback) => {
      console.log(value);
      let reg = /^((\+?86)|(\(\+86\)))?(13[012356789][0-9]{8}|15[012356789][0-9]{8}|18[023456789][0-9]{8}|147[0-9]{8}|1349[0-9]{7})$/;
      if (reg.test(value) === false) {
        callback(new Error("请输入正确手机号码"));
      }
    };
    var psgNameCn = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入乘机人姓名"));
      }
    };
    return {
      dialogVisible: false,
      loading: true,
      // 订单号
      orderNo: null,
      // 订单信息
      detail: null,
      // 航班信息
      ordersData: null,
      // 乘客数量
      passengerVal: 1,
      // 乘客类型
      psgTypeOption: [
        {
          value: '1',
          label: "成人",
          disabled: false
        },
        {
          value: '2',
          label: "儿童",
          disabled: false
        }
      ],
      // 联系人信息
      contact: {
        contactEmail: "",
        contactName: "",
        remark: "",
        contactPhone: ""
      },
      // 旅客证件
      cardType: [
        {value: '0', name: "身份证"},
        {value: '1', name: "护照"},
        // {value: 2, name: ''},
        // {value: 3, name: '身份证'},
        {value: '4', name: "港澳通行证"},
        {value: '5', name: "台胞证"},
        {value: '6', name: "台湾通行证"},
        {value: '7', name: "回乡证"},
        {value: '8', name: "军官证"},
        {value: '9', name: "其他"}
      ],
      // 是否同意胤之旅规则
      checked: false,
      // 乘客信息
      passenger: [
        {
          // 出生日期，格式：yyyy-MM-dd
          birthday: "",
          // 证件号码
          cardNo: "",
          // 证件类型，0-身份证 1-护照 4-港澳通行证 5-台胞证 6-台湾通行证 7-回乡证 8-军官证 9-其他
          cardType: 0,
          // 是否保存至常旅客，0-不保存 1-保存
          isSaveFrequent: 0,
          // 手机号
          mobile: "",
          // 名字
          psgName: "",
          // 乘客类型，1-成人 2-儿童
          psgType: '1',
          sex: 0
        }
      ],
      // 乘客类型
      psgType: 3,
      // 输入框验证规则
      rules: {
        cardNo: [{validator: cardNo, required: true, trigger: "blur"}],
        input: [{required: true, message: "请填写证件号码", trigger: "blur"}],
        mobile: [{validator: mobile, required: true, trigger: "blur"}],
        psgNameCn: [{validator: psgNameCn, required: true, trigger: "blur"}],
        contactName: [
          {validator: psgNameCn, required: true, trigger: "blur"}
        ],
        contactPhone: [{validator: mobile, required: true, trigger: "blur"}]
        // email: [
        //   { required: true, message: '请输入邮箱地址', trigger: 'blur' },
        //   { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        // ]
      },
      // 成人数量
      adultNub: 1,
      // 儿童数量
      childNub: 0,
      // 成人价格
      adultPrice: 0,
      // 儿童价格
      childPrice: 0,
      AllPrice: 0,
      PriceText: "",
      rate: 1,
      image_url_airline_blank_map: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`,
    };
  },
  methods: {
    // 计算周几
    Getweek(index) {
      let time = this.$moment(index).valueOf();
      let str = [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期日"
      ];
      let week = Number(this.$moment(time).format("E")) - 1;
      return str[week];
    },
    // 通过身份证获取生日
    fromIdCard(index) {
      let idCard = this.passenger[index].cardNo;
      let birthday = "";
      if (idCard != null && idCard != "") {
        if (idCard.length == 15) {
          birthday = "19" + idCard.substr(6, 6);
        } else if (idCard.length == 18) {
          birthday = idCard.substr(6, 8);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
      }
      this.passenger[index].birthday = birthday;
    },
    // 获取信息
    getAirParams() {
      const __this = this;
      __this.orderNo = __this.$route.query.ordersNo
      let params = {
        orderNo: __this.orderNo
      }
      detail(params).then(res => {
        console.log(res)
        __this.loading = false
        __this.detail = res.detail
        __this.ordersData = _.chain(res.detail.passengers[0].segments)
          .filter(function (m) {
            // http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/domestic-buyer-manage-order-detail-controller/detailUsingPOST
            // 状态 1,2-正常，31-改签中，34-已改签，41-退票中，42-已退票
            // 仅显示status===31的数据
            return m.status === `31`;
          })
          .value()
        ;
        __this.passenger = res.detail.passengers
        __this.contact.contactEmail = res.detail.contactEmail
        __this.contact.contactName = res.detail.contactName
        __this.contact.remark = res.detail.cancelRemark
        __this.contact.contactPhone = res.detail.contactMobile
        __this.AllPrice = res.detail.totalFillingAmount
      })
    },
    // 深克隆
    deepClone(obj) {
      let _obj = JSON.stringify(obj);
      let objClone = JSON.parse(_obj);
      return objClone;
    },
    // 计算成人数量
    compAdultNub(data) {
      return data.psgType === 1;
    },
    // 计算儿童数量
    compChildNub(data) {
      return data.psgType === 2;
    },
    // 底部文字部分
    // 提交表单
    submit() {
      let params = {
        orderNo: this.orderNo,
        paymentChannel: 'YEEPAY',
        paymentType: 12,
        totalFee: this.detail.totalFillingAmount
      }
      pay(params).then(res => {
        console.log(res)
        window.open(res.htmlStr)
        this.dialogVisible = true
      }).catch(err => {
        this.$notify.error({
          title: '错误',
          message: '系统报错'
        });
      })
    }
  },
  watch: {
    passenger: {
      handler: function () {
        this.adultNub = this.passenger.filter(this.compAdultNub).length;
        this.childNub = this.passenger.filter(this.compChildNub).length;
      },
      deep: true
    }
  },
  created() {
    this.getAirParams();
  }
};
